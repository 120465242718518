import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";

import MainLayOut from "./component/MainLayOut";
import Home from "./pages/Home";
import { useEffect } from "react";
import Login from "./pages/login/Login";
import { useDispatch, useSelector } from "react-redux";
import AddSuperCategory from "./pages/admin-magangement/supercategory/AddSuperCategory";
import SuperCategoryList from "./pages/admin-magangement/supercategory/SuperCategoryList";
import EditSuperCategory from "./pages/admin-magangement/supercategory/EditSuperCategory";
import AddCategory from "./pages/admin-magangement/category/AddCategory";
import CategoryList from "./pages/admin-magangement/category/CategoryList";
import EditCategory from "./pages/admin-magangement/category/EditCategory";
import AddSubCategory from "./pages/admin-magangement/sub_category/AddSubCategory";
import SubCategoryList from "./pages/admin-magangement/sub_category/SubCategoryList";
import EditSubCategory from "./pages/admin-magangement/sub_category/EditSubCategory";
import AddBrand from "./pages/admin-magangement/brand/AddBrand";
import BrandList from "./pages/admin-magangement/brand/BrandList";
import BookedOrder from "./pages/order/BookedOrder";
import ProcessedOrder from "./pages/order/ProcessedOrder";
import DispatchedOrder from "./pages/order/DispatchedOrder";
import OutOfDeliveryOrder from "./pages/order/OutOfDeliveryOrder";
import DeliveredOrder from "./pages/order/DeliveredOrder";
import CancelledOrder from "./pages/order/CancelledOrder";
import OrderDetails from "./pages/order/OrderDetails";
import AssignOrder from "./pages/order/AssignOrder";
import OrderInvoice from "./pages/order/OrderInvoice";
import ProcessingItems from "./pages/reports/ProcessingItems";
import WalletUpdate from "./pages/reports/WalletUpdate";
import UpdatePayment from "./pages/reports/UpdatePayment";
import RemoveWalletUpdates from "./pages/reports/RemoveWalletUpdates";
import WalletHistory from "./pages/reports/WalletHistory";
import AddSlider from "./pages/sliders/AddSlider";
import ListSlider from "./pages/sliders/ListSlider";
import UpdateSlider from "./pages/sliders/UpdateSlider";
import AddProduct from "./pages/admin-magangement/product/AddProduct";
import ProductList from "./pages/admin-magangement/product/ProductList";
import { getOrder, getpartners } from "./redux/Order/OrderSlice";

function ScrollToTop() {
  const { loginData, isAuth } = useSelector((store) => store.auth);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    let interval;
    if (loginData) {
      const initialSetup = async () => {
        try {
          dispatch(getOrder(loginData._id));
          dispatch(getpartners(loginData._id));
        } catch (error) {
          console.error(error);
        }
      };

      initialSetup();
      interval = setInterval(initialSetup, 2000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [loginData._id]);


  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/store/order-invoice" element={<OrderInvoice />} />
          <Route path="/store" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route
              path="/store/addsupersategory"
              element={<AddSuperCategory />}
            />
            <Route
              path="/store/listsupersategory"
              element={<SuperCategoryList />}
            />
            <Route
              path="/store/updatesupersategory/:slugurl"
              element={<EditSuperCategory />}
            />
            <Route path="/store/addcategory" element={<AddCategory />} />
            <Route path="/store/listcategory" element={<CategoryList />} />
            <Route
              path="/store/editcategory/:slugurl"
              element={<EditCategory />}
            />
            <Route path="/store/addsubcategory" element={<AddSubCategory />} />
            <Route
              path="/store/listsubcategory"
              element={<SubCategoryList />}
            />
            <Route
              path="/store/editSubCategory/:slugurl"
              element={<EditSubCategory />}
            />
            <Route path="/store/addBrand" element={<AddBrand />} />
            <Route path="/store/listbrand" element={<BrandList />} />

            <Route path="/store/addProduct" element={<AddProduct />} />
            <Route path="/store/productList" element={<ProductList />} />
            <Route path="/store/addProduct/productId" element={<AddProduct />} />


            <Route path="/store/order/bookedOrder" element={<BookedOrder />} />
            <Route
              path="/store/order/processedOrder"
              element={<ProcessedOrder />}
            />
            <Route
              path="/store/order/dispatchedOrder"
              element={<DispatchedOrder />}
            />
            <Route
              path="/store/order/outOfDeliveryOrder"
              element={<OutOfDeliveryOrder />}
            />
            <Route
              path="/store/order/deliveredOrder"
              element={<DeliveredOrder />}
            />
            <Route
              path="/store/order/cancelledOrder"
              element={<CancelledOrder />}
            />
            <Route
              path="/store/orderdetails/:orderid"
              element={<OrderDetails />}
            />
            <Route
              path="/store/assign-delivery/:orderid"
              element={<AssignOrder />}
            />

            <Route
              path="/store/processingitems"
              element={<ProcessingItems />}
            />
            <Route path="/store/wallet-update" element={<WalletUpdate />} />
            <Route path="/store/updatepayment" element={<UpdatePayment />} />
            <Route path="/store/substract-wallet" element={<RemoveWalletUpdates />} />
            <Route path="/store/wallet-history" element={<WalletHistory />} />

            <Route path="/store/addslider" element={<AddSlider />} />
            <Route path="/store/listslider" element={<ListSlider />} />
            <Route
              path="/store/updateslider/:slugUrl"
              element={<UpdateSlider />}
            />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
