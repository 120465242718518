import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  employeestotal: localStorage.getItem("employeestotal")
    ? JSON.parse(localStorage.getItem("employeestotal")).sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : -1
      )
    : [],
  deliveryPersions: localStorage.getItem("employeestotal")
    ? JSON.parse(localStorage.getItem("employeestotal")).filter(
        (delivery) => delivery.DeliveryBoy === true
      )
    : [],
  isLoading: true,
  employeesLoading: true,
  delemployeesLoading: true,
};

export const getEmployees = createAsyncThunk(
  "Employees/getEmployees",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/employee/all`;
      const resp = await axios(url);
      return resp.data.employees;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const employeesPost = createAsyncThunk(
  "employees/employeesPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/employee/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("employee Not create");
    }
  }
);

export const employeesUpdate = createAsyncThunk(
  "employees/employeesUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/employee/${formData.employeeid}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("employee Not create");
    }
  }
);

export const employeesDelete = createAsyncThunk(
  "employees/employeesDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/employee/${id}`;
      const resp = await axios.delete(url, id, config);
      console.log(resp.data);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("Employees Not create");
    }
  }
);

const EmployeesSlice = createSlice({
  name: "Employees",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.employeestotal = action.payload.sort((a, b) =>
          a.createdAt > b.createdAt ? 1 : -1
        );
        state.deliveryPersions = state.employeestotal.filter(
          (delivery) => delivery.DeliveryBoy === true
        );
        state.isLoading = false;
        state.employeesLoading = false;
        localStorage.setItem(
          "employeestotal",
          JSON.stringify(state.employeestotal)
        );
      })
      .addCase(getEmployees.rejected, (state) => {
        state.isLoading = true;
      })

      .addCase(employeesPost.pending, (state) => {
        state.employeesLoading = true;
      })
      .addCase(employeesPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.employeestotal = [
            ...state.employeestotal,
            action.payload.employee,
          ].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
          state.deliveryPersions = state.employeestotal.filter(
            (delivery) => delivery.DeliveryBoy === true
          );
          localStorage.setItem(
            "employeestotal",
            JSON.stringify(state.employeestotal)
          );
        }
        state.employeesLoading = false;
      })
      .addCase(employeesPost.rejected, (state) => {
        state.employeesLoading = true;
      })

      .addCase(employeesUpdate.pending, (state) => {
        state.employeesLoading = true;
      })
      .addCase(employeesUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.employeestotal = state.employeestotal.filter(
            (employee) => employee._id !== action.payload.employee._id
          );
          state.employeestotal = [
            ...state.employeestotal,
            action.payload.employee,
          ].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
          state.deliveryPersions = state.employeestotal.filter(
            (delivery) => delivery.DeliveryBoy === true
          );
          localStorage.setItem(
            "employeestotal",
            JSON.stringify(state.employeestotal)
          );
        }
        state.employeesLoading = false;
      })
      .addCase(employeesUpdate.rejected, (state) => {
        state.employeesLoading = true;
      })

      .addCase(employeesDelete.pending, (state) => {
        state.delemployeesLoading = true;
      })
      .addCase(employeesDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.employeestotal = state.employeestotal.filter(
            (employee) => employee._id !== action.payload.id
          );
          state.deliveryPersions = state.employeestotal.filter(
            (delivery) => delivery.DeliveryBoy === true
          );
          localStorage.setItem(
            "employeestotal",
            JSON.stringify(state.employeestotal)
          );
        }
        state.delemployeesLoading = false;
      })
      .addCase(employeesDelete.rejected, (state) => {
        state.delemployeesLoading = true;
      });
  },
});
export default EmployeesSlice.reducer;
