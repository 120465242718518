import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/Authentication/AuthSlice";
import { MdAdminPanelSettings } from "react-icons/md";
import { TbDeviceIpadMinus } from "react-icons/tb";
import { GrUserAdmin } from "react-icons/gr";
import { FaFileArchive } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { CiFileOn } from "react-icons/ci";
import { RiFileCopy2Fill } from "react-icons/ri";
import { IoMdPerson } from "react-icons/io";
import { GoChecklist } from "react-icons/go";
import { IoIosImages } from "react-icons/io";
import { TbReportSearch } from "react-icons/tb";
import { FaBook } from "react-icons/fa6";
import { CiBellOn } from "react-icons/ci";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import { FaListUl } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";

const Hadear = () => {
  const { loginData, showMenu, role, activeMenu, activeInsideMenu } =
    useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const handleFullScreen = () => {
    if (!isFullScreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => setFullScreen(!isFullScreen))
        .catch((err) =>
          console.error("Error attempting to enable full-screen mode", err)
        );
    } else {
      document
        .exitFullscreen()
        .then(() => setFullScreen(!isFullScreen))
        .catch((err) =>
          console.error("Error attempting to exit full-screen mode", err)
        );
    }
  };

  const handleEscapeKey = () => {
    setFullScreen(false);
    console.log("hii manoj");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleEscapeKey();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const logoutClick = () => {
    dispatch(authActions.signout());
    navigate("/");
  };

  const closeShowMenu = () => {
    dispatch(authActions.setShowMenu(""));
  };
  const handleShowMenu = (value) => {
    if (showMenu === value) {
      dispatch(authActions.setShowMenu(""));
    } else {
      dispatch(authActions.setShowMenu(value));
    }
  };
  const handleMenu = (value) => {
    if (activeMenu === value) {
      dispatch(authActions.setActiveMenu(""));
    } else {
      dispatch(authActions.setActiveMenu(value));
    }
  };
  const handleInsideMenu = (value) => {
    if (activeInsideMenu === value) {
      dispatch(authActions.setActiveInsideMenu(""));
    } else {
      dispatch(authActions.setActiveInsideMenu(value));
    }
  };
  return (
    <>
      <div
        className="min-h-screen flex flex-col lg:ps-64 w-full"
        ref={tableRef}
      >
        <div
          id="application-sidebar"
          className="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed inset-y-0 start-0 lg:z-30 z-60 w-64 bg-white border-e border-default-200 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-default-50 rtl:hs-overlay-open:translate-x-0 rtl:translate-x-full rtl:lg:translate-x-0 print:hidden"
          style={{ backgroundColor: "#4d44b5" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: ".5px dashed #fff",
                padding: "10px 20px",
                gap: "15px",
              }}
            >
              <Link to="/store">
                <img
                  src="/assets/brand.jpeg"
                  alt="logo"
                  style={{ borderRadius: "50%", height: "40px" }}
                />
              </Link>
              <Link to="/store">
                <div
                  style={{ color: "#fff", fontSize: "22px", fontWeight: "600" }}
                >
                  KisanGhar
                </div>
              </Link>
            </div>
          </div>
          <div
            className="h-[calc(100%-72px)] p-2"
            data-simplebar
            style={{ overflowY: "scroll" }}
          >
            <ul className="admin-menu w-full flex flex-col gap-1.5">
              <li className="menu-item">
                <Link
                  className={
                    "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                  }
                  to="/store"
                >
                  <i className="ph-duotone ph-gauge text-xl" />
                  Dashboard
                </Link>
              </li>

              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("orders")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium  rounded-md transition-all menuBg"
                  >
                    <FaBook />
                    Orders
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  <div id="report">
                    <ul className="space-y-2 mt-2 ms-4">
                      <li className="menu-item">
                        <Link
                          className={
                            "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                          }
                          to="/store/order/bookedOrder"
                        >
                          <TbReportSearch />
                          Pending Orders
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link
                          className={
                            "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                          }
                          to="/store/order/processedOrder"
                        >
                          <TbReportSearch />
                          Active Orders
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link
                          className={
                            "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                          }
                          to="/store/order/dispatchedOrder"
                        >
                          <TbReportSearch />
                          Assigned Orders
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link
                          className={
                            "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                          }
                          to="/store/order/outOfDeliveryOrder"
                        >
                          <TbReportSearch />
                          Delivering Order
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link
                          className={
                            "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                          }
                          to="/store/order/deliveredOrder"
                        >
                          <TbReportSearch />
                          Delivered Orders
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link
                          className={
                            "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                          }
                          to="/store/order/cancelledOrder"
                        >
                          <TbReportSearch />
                          Cancelled Orders
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <header className="sticky top-0 h-18 flex z-40 w-full border-b border-default-200/80 bg-white/80 backdrop-blur-3xl dark:bg-default-50 print:hidden">
          <nav className="flex items-center gap-4 w-full px-6">
            <div className="lg:hidden flex">
              <button
                type="button"
                className="text-default-500 hover:text-default-600"
                data-hs-overlay="#application-sidebar"
                aria-controls="application-sidebar"
                aria-label="Toggle navigation"
              >
                <i data-lucide="align-justify" className="w-6 h-6" />
              </button>
            </div>
            <div className="lg:hidden flex">
              <Link to="admin-dashboard.html">
                <img
                  src="/assets/brand.jpeg"
                  alt="logo"
                  className="h-10 w-full flex dark:hidden"
                />
              </Link>
            </div>
            <div className="flex items-center gap-4 ms-auto">
              <div className="lg:flex hidden">
                <button
                  data-toggle="fullscreen"
                  onClick={handleFullScreen}
                  className="inline-flex flex-shrink-0 justify-center items-center gap-2 h-10 w-10 rounded-md font-medium text-default-700 align-middle bg-default-100 hover:text-primary transition-all text-xs"
                >
                  {!isFullScreen ? (
                    <>
                      <i className="ph-duotone ph-arrows-out text-2xl" />
                    </>
                  ) : (
                    <>
                      <i className="ph-duotone ph-arrows-in text-2xl" />
                    </>
                  )}
                </button>
              </div>
              <div className="flex">
                <div className="hs-dropdown relative inline-flex">
                  <button
                    id="hs-dropdown-with-header"
                    type="button"
                    className="hs-dropdown-toggle inline-flex justify-center items-center gap-2 flex-shrink-0 rounded-md font-medium text-default-700 align-middle transition-all text-xs"
                  >
                    <img
                      className="inline-block h-10 w-10 rounded-full"
                      src="/assets/brand.jpeg"
                      alt="Avatar"
                    />
                    <div
                      className="lg:block text-start hidden"
                      onClick={() => logoutClick()}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className="text-xs font-semibold text-default-700"
                        style={{ textTransform: "capitalize" }}
                      >
                        {loginData.storeName}
                      </p>
                      <p
                        className="text-xs text-default-500 mt-1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {loginData.storeType}
                      </p>
                    </div>
                  </button>
                  <div className="hs-dropdown-menu mt-2 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[12rem] shadow-md rounded-lg p-2 border border-default-200 bg-white dark:bg-default-50">
                    <span
                      onClick={() => logoutClick()}
                      style={{ cursor: "pointer" }}
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm font-medium text-red-400 hover:bg-red-400/10"
                    >
                      <i className="w-4 h-4" data-lucide="log-out" />
                      Log out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Hadear;
