import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { productDelete } from "../../redux/Product/ProductSlice";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import moment from "moment";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const DeliveredOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deliveredorder } = useSelector((store) => store.order);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectOrder, setSelectOrder] = useState("");
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useState("");
  const [filterOrder, setFilterOrder] = useState([]);

  useEffect(() => {
    if (deliveredorder.length > 0) {
      setOrders([...deliveredorder]);
    } else {
      setOrders([]);
    }
  }, [deliveredorder]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const url = `${Baseurl}/api/v1/order/Orderdelivered`;
  //     const fetchOrder = await axios.get(url);
  //     if (fetchOrder.data.success === true) {
  //       const orderTotal = fetchOrder.data.orders;
  //       setOrders(orderTotal.reverse());
  //     }
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    const result = orders.filter((order) => {
      return order._id.toLowerCase().slice(18).match(search.toLowerCase());
    });
    setFilterOrder(result);
  }, [search, orders]);

  const showModal = (e, order) => {
    setIsDeleteOpen(true);
    setSelectOrder(order);
  };

  const handleOk = () => {
    setIsDeleteOpen(false);
  };

  const handleCancel = () => {
    setIsDeleteOpen(false);
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Delivered Orders
            </h4>
            <div className="space-y-1">
              <div className="form-flex">
                <div className="textfieldcontainer" style={{ width: "100%" }}>
                  <TextField
                    label="Search by orderId"
                    variant="outlined"
                    className="textfield"
                    type="text"
                    id="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ textTransform: "uppercase" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div
            className="shadow rounded-lg bg-white dark:bg-default-50"
            style={{ marginBottom: "80px" }}
          >
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden  table-striped">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        OrderID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        NetPayable
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        PaymentStatus
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Assigned Persion
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterOrder &&
                      filterOrder.map((indOrder, index) => (
                        <tr
                          style={{
                            background: index % 2 === 0 ? "#eee" : "#fff",
                          }}
                        >
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                          >
                            {index + 1}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                            style={{ color: "green", cursor: "pointer" }}
                          >
                            <Link to={`/store/orderdetails/${indOrder._id}`}>
                              {indOrder._id.slice(18)}
                            </Link>
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.ClientName}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {moment(indOrder.createdAt).format("MMM/DD/YYYY")}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder?.clientAddress?.StreetDet},
                            {indOrder?.clientAddress?.AreaDet},
                            {indOrder?.clientAddress?.City}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.GrandTotal}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.PaymentStatus}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.DeliveryPersionName}
                            <br />
                            {indOrder.DeliveryPersionmobile}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveredOrder;
